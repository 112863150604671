<template>
  <div class="forgot_container">
    <div class="divider"></div>
    <div class="forgot_cell">
    <div class=" text-2xl font-500">Forgot your password?</div>
    <div class=" text-sm font-400 mt-4 mb-6">Tell us your email address, and we'll get you back on track in no time.</div>

    <div class=" text-base font-500">Email Address</div>
    <input v-model="email_add" ref="email_input" class="btn mt-2" type="text" autofocus>
    <input :disabled='isEmailFomat' class="sub_btn" type="button" value="Reset Password">
    </div>
    <router-link class="back_btn" to='/login'> Back to sign in</router-link>
  </div>
</template>

<script>
import { emailReg } from '@/utils/validation'

export default {
  data () {
    return {
      email_add: '',
      submitDisable: true
    }
  },
  mounted () {
    this.$refs.email_input.focus()
  },
  computed: {
    isEmailFomat () {
      return !emailReg.reg.test(this.email_add)
    }
  }
}
</script>

<style scoped>
.btn {
  height: 56px;
  width: 100%;
  border-radius: 2px;
  background: #fefefe;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  padding: .5rem;
  cursor: pointer;
  outline: none;
}
.forgot_cell{
  width: 550px;
  padding: 25px;
  margin: 0 auto;
}
.forgot_container{
  padding-top: 160px;
}
.divider {
  width: 100px;
  height: 6px;
  background: #bc9a41;
  border-radius: 4px;
  margin: 0 auto 20px;
}
.back_btn{
  display: block;
  text-align: center;
  margin: 20px;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}
.sub_btn{
  height: 56px;
  width: 100%;
  border-radius: 2px;
  background: #163257;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 20px;
  color: #fefefe;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  letter-spacing: 1px;
  margin-top: 2rem;
}
.sub_btn:disabled{
  background: #efefef;
  color: #ddd;
  border-color: #efefef;
  cursor: not-allowed;
}
@media only screen and (max-width: 600px){
.forgot_container{
  padding: 10vh 0 0;
}
.divider {
  width: 100px;
  height: 6px;
  background: #bc9a41;
  border-radius: 4px;
  margin: 0 auto 0;
}
.forgot_cell{
  width: 100%;
  padding: 5vw;
  margin: 0 auto;
}
}
</style>
